import { client } from './axios';
import { getKeyWord } from 'utils/common';

const fetchListNFT = ({ keyword }) => client.get(`v1/nft/nfts/blocked-nfts?offset=0&keyword=${getKeyWord(keyword)}`);
const fetchNFTById = (id) => client.get(`/v1/nft/nfts/${getKeyWord(id)}/details`);
const deListNFT = (id) => client.put(`/v1/nft/nfts/${getKeyWord(id)}/block`);
const reListNFT = (id) => client.put(`/v1/nft/nfts/${getKeyWord(id)}/unblock`);
const fetchHistory = (id) => client.get(`/v1/nft/nfts/${getKeyWord(id)}/event-history?limit=10`);

export const deListNFTService = {
  fetchListNFT,
  fetchNFTById,
  deListNFT,
  reListNFT,
  fetchHistory,
};
