import React from 'react';
import {
  Grid,
  Paper,
  FormControl,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  Dialog,
  Stack,
  Pagination,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ArtistTableRow from './ArtistTableRow';
import { PopupSave, PopupDelete } from 'views/Checkout';
import { usePopup } from 'hooks';
import { contractService } from 'services/contract';
import { storageService } from 'services/storage';
import { useQuery, useMutation } from 'react-query';
import { Spinner } from 'components';
import { TableCellCustom, TableRowCustom } from 'views/ContractWhitelist/ContractWhislist';

function ArtistCreatorWhitelist() {
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: { name: '', address: '' },
  });
  //pagination
  const [page, setPage] = React.useState(1);
  const handleChange = async (event, value) => {
    await setPage(value);
    await refetchContracts();
  };
  const [isOpenSave, onOpenSave, onCloseSave] = usePopup(false);
  const [isOpenDelete, onOpenDelete, onCloseDelete] = usePopup(false);

  //get list contracts
  const {
    data: quotes = {},
    refetch: refetchContracts,
    isFetching,
  } = useQuery(['fetchContracts'], () => contractService.fetchContract({ offset: (page - 1) * 10, limit: 10 }));
  const contracts = quotes.contracts?.sort((a, b) => b.createdAt - a.createdAt);

  const { mutate: createContract, isLoading } = useMutation(contractService.createContracts, {
    onSuccess: (data, body) => {
      onRefresh();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => createContract(values))();
  };

  const onRefresh = () => {
    reset({
      source: '',
      address: '',
    });
    refetchContracts();
  };

  //PopupSave
  const handleClickCancelPopupSave = () => {
    storageService.clearContract();
    storageService.clearSource();
    onCloseSave();
  };

  const handleConfirmPopupSave = () => {
    let address = storageService.getContract();
    let body = storageService.getSource();
    contractService.updateContracts(address, body).then(() => {
      onRefresh();
    });
    storageService.clearContract();
    storageService.clearSource();
    onCloseSave();
  };

  const info = {
    title: 'Save',
    content: 'Do you want to save your edit?',
  };
  //PopupDelete
  const handleClickCancelPopupDelete = () => {
    onCloseDelete();
    storageService.clearContract();
    onRefresh();
  };

  const handleConfirmPopupDelete = () => {
    let address = storageService.getContract();
    contractService.deleteContracts(address).then(() => {
      onRefresh();
    });
    storageService.clearContract();
    onCloseDelete();
  };

  return (
    <div>
      <Paper className='mb-4 rounded-lg p-8' style={{ minHeight: '175px' }}>
        <Grid container columns={12} spacing={3} mb={3}>
          <Grid item xl={3} md={3} xs={12}>
            <Controller
              name='name'
              defaultValue={''}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <label>Artist/Creator name</label>
                  <TextField placeholder='Enter Artist/Creator Name' {...field} />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xl={9} md={9} xs={12}>
            <Controller
              name='address'
              defaultValue={''}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <label>Wallet Address</label>
                  <TextField placeholder='Enter Contract Address' {...field} />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Button disabled={isFetching || isLoading} startIcon={<AddIcon />} onClick={handleClickSubmit}>
          Add new
        </Button>
      </Paper>
      <Paper className='rounded-lg p-8' style={{ minHeight: '392px' }}>
        <Spinner spinning={isFetching || isLoading}>
          <div className=' flex flex-col items-center overflow-x-auto'>
            <Table>
              <TableHead>
                <TableRowCustom>
                  <TableCellCustom>Artist/Creator name</TableCellCustom>
                  <TableCellCustom>Wallet Address</TableCellCustom>
                  <TableCellCustom>ACTIONS</TableCellCustom>
                </TableRowCustom>
              </TableHead>
              <TableBody>
                {contracts &&
                  contracts.map((contract, index) => (
                    <ArtistTableRow key={index} {...contract} onOpenSave={onOpenSave} onOpenDelete={onOpenDelete} />
                  ))}
              </TableBody>
            </Table>
            <Stack className='mt-3' spacing={2}>
              <Pagination
                count={Math.ceil(quotes.total / 10) || 1}
                variant='outlined'
                shape='rounded'
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </Spinner>
      </Paper>
      <Dialog open={isOpenSave} maxWidth='lg' className='w-81 h-43'>
        <PopupSave onCancel={handleClickCancelPopupSave} onConfirm={handleConfirmPopupSave} info={info} />
      </Dialog>
      <Dialog open={isOpenDelete} maxWidth='lg'>
        <PopupDelete onCancel={handleClickCancelPopupDelete} onConfirm={handleConfirmPopupDelete} />
      </Dialog>
    </div>
  );
}

export default ArtistCreatorWhitelist;
