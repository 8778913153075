import React from 'react';
import { Paper, FormControl, TextField, Button, InputBase, Table, TableHead, TableBody, Dialog } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Spinner } from 'components';
import { TableRowCustom, TableCellCustom } from '../ContractWhitelist/ContractWhislist';
import RestoreIcon from '@mui/icons-material/Restore';
import { usePopup } from 'hooks';
import { PopupDeList, PopupSave } from 'views/Checkout';
import { deListNFTService } from 'services/delist';
import { useQuery, useMutation } from 'react-query';
import { storageService } from 'services/storage';

function DeListNFT() {
  const { control, handleSubmit, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues: { url: '', search: '' },
  });
  const infoRelist = {
    title: 'Relist',
    content: 'Are you sure you want to relist the item on Spores Marketplace?',
  };
  const [isOpenPopup, onOpenPopup, onClosePopup] = usePopup();
  const [isOpenPopupRelist, onOpenPopupRelist, onClosePopupRelist] = usePopup();

  //fetch list NFT
  const {
    data: listNFT,
    isLoading: isLoadingListNFT,
    refetch: fetchListNFT,
  } = useQuery(['listNFT'], () => deListNFTService.fetchListNFT({ keyword: getValues('search') }));

  const handleClickPreview = () => {
    handleSubmit((values) => {
      storageService.saveKeyword(values.url);
      onOpenPopup();
    })();
  };

  //handle click relist
  const { mutate: reListNFT } = useMutation(deListNFTService.reListNFT, {
    onSuccess: (data, body) => {
      onRefresh();
    },
  });

  //pop up relist
  const handleOpenPopupRelist = (id) => {
    storageService.saveKeyword(id);
    onOpenPopupRelist();
    onRefresh();
  };

  const handleClosePopupRelist = () => {
    storageService.clearKeyword();
    onClosePopupRelist();
    onRefresh();
  };

  const handleClickRelist = () => {
    let keyword = storageService.getKeyword();
    reListNFT(keyword);
    onClosePopupRelist();
    onRefresh();
    storageService.clearKeyword();
  };

  //refresh list NFT
  const onRefresh = () => {
    reset({
      url: '',
      search: '',
    });
    fetchListNFT();
  };
  return (
    <div>
      <Paper className='mb-4 rounded-lg p-8'>
        <div className='flex gap-4'>
          <Controller
            name='url'
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField placeholder='Enter Item’s URL on Spores Marketplace' {...field} />
              </FormControl>
            )}
          />
          <Button startIcon={<AddIcon />} onClick={handleClickPreview}>
            Preview
          </Button>
        </div>
      </Paper>
      <Paper className='mb-4 rounded-lg p-8' style={{ minHeight: '500px' }}>
        <Controller
          name='search'
          defaultValue={''}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder='Search items'
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    fetchListNFT();
                  }}
                />
              </Search>
            </FormControl>
          )}
        />
        <div className='mt-6'>
          <Spinner spinning={isLoadingListNFT}>
            <Table>
              <TableHead>
                <TableRowCustom>
                  <TableCellCustom>ITEM</TableCellCustom>
                  <TableCellCustom style={{ width: '20%', textAlign: 'center' }}>PREVIEW</TableCellCustom>
                  <TableCellCustom style={{ width: '13%' }}>
                    <span style={{ paddingLeft: '12px' }}>ACTIONS</span>
                  </TableCellCustom>
                </TableRowCustom>
              </TableHead>
              <TableBody>
                {listNFT &&
                  listNFT.blocked_nfts?.map((item, index) => (
                    <TableRowCustom key={index}>
                      <TableCellCustom>{`https://spores.app/explore/${item.id}`}</TableCellCustom>
                      <TableCellCustom className='flex justify-center'>
                        <img className='max-h-10' src={item.media[0].url} />
                      </TableCellCustom>
                      <TableCellCustom>
                        <ButtonReList startIcon={<RestoreIcon />} onClick={() => handleOpenPopupRelist(item.id)}>
                          Re-list
                        </ButtonReList>
                      </TableCellCustom>
                    </TableRowCustom>
                  ))}
              </TableBody>
            </Table>
          </Spinner>
        </div>
      </Paper>
      <Dialog open={isOpenPopup} maxWidth='xl'>
        <PopupDeList onClose={onClosePopup} onRefresh={onRefresh} />
      </Dialog>
      <Dialog open={isOpenPopupRelist} maxWidth='lg' className='w-81 h-43'>
        <PopupSave onCancel={handleClosePopupRelist} onConfirm={handleClickRelist} info={infoRelist} />
      </Dialog>
    </div>
  );
}

const ButtonReList = styled(Button)({
  background: 'none!important',
  border: 'none',
  minWidth: '100px',
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(0),
  marginLeft: 0,
  width: '100%',
  border: '2px solid #DFE0E6',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  width: '100%',
}));

export default DeListNFT;
