import { getKeyWord } from 'utils/common';

const getContract = () => JSON.parse(localStorage.getItem('contract')) ?? {};
const saveContract = (contract) => localStorage.setItem('contract', JSON.stringify(contract));
const clearContract = () => localStorage.removeItem('contract');

const getSource = () => JSON.parse(localStorage.getItem('source')) ?? {};
const saveSource = (contract) => localStorage.setItem('source', JSON.stringify(contract));
const clearSource = () => localStorage.removeItem('source');

const getKeyword = () => JSON.parse(localStorage.getItem('keyword')) ?? {};
const saveKeyword = (keyword) => localStorage.setItem('keyword', JSON.stringify(getKeyWord(keyword)));
const clearKeyword = () => localStorage.removeItem('keyword');
export const storageService = {
  getContract,
  saveContract,
  clearContract,
  getSource,
  saveSource,
  clearSource,
  getKeyword,
  saveKeyword,
  clearKeyword,
};
