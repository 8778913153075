import React from 'react';
import { Paper, Typography, Avatar, Box, Button, Dialog } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { PopupSave } from 'views/Checkout';
import { usePopup } from 'hooks';
import { storageService } from 'services/storage';
import { useQuery, useMutation } from 'react-query';
import { deListNFTService } from 'services/delist';
import { shorten } from 'utils/common';

const ACTION_TYPES = {
  0: 'Minted',
  1: 'Listed',
  2: 'Unlisted',
  3: 'Bid',
  4: 'Purchased',
  5: 'Transferred',
};

function PopupDeList({ onClose, onRefresh }) {
  const classes = useStyles();
  const [value, setValue] = React.useState('1');

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const infoDelist = {
    title: 'Delist',
    content: 'Are you sure you want to delist the item?',
  };
  const [isOpenPopupDelist, onOpenPopupDelist, onClosePopupDelist] = usePopup();

  //fetch NFT by id
  let keyword = storageService.getKeyword();
  const { data: NFTDetail } = useQuery(['listNFT'], () => deListNFTService.fetchNFTById(keyword));
  const { data: listHistory } = useQuery(['listHistory'], () => deListNFTService.fetchHistory(keyword));

  const Owner = NFTDetail?.ownerships?.[0]?.owner;
  const Creator = NFTDetail.creator;
  const Collection = NFTDetail.subCollection;

  //handle click delist
  const { mutate: deListNFT } = useMutation(deListNFTService.deListNFT, {
    onSuccess: (data, body) => {
      onRefresh();
    },
  });

  const handleClickDelist = () => {
    deListNFT(keyword);
    onClosePopupDelist();
    onClose();
    onRefresh();
    storageService.clearKeyword();
  };

  const handleClose = () => {
    storageService.clearKeyword();
    onClose();
    onRefresh();
  };
  return (
    <Paper className={`flex justify-between max-w-7xl ${classes.root}`}>
      <CloseIcon onClick={handleClose} style={{ position: 'absolute', top: 32, right: 36, cursor: 'pointer' }} />
      <Box style={{ width: '44%' }}>
        <img className='w-full h-full' src={NFTDetail.media?.[0].url} />
      </Box>
      <Box style={{ width: '54%' }} className='flex flex-col'>
        <Typography component='p' className='text-3xl mb-5'>
          {NFTDetail?.name}
        </Typography>
        <Box className='flex mb-8'>
          <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2' src={Owner?.avatar}></Avatar>
          <Box className='flex flex-col justify-center'>
            <Typography variant='h6' color='#949496'>
              Owner
            </Typography>
            <Typography variant='h5'>{Owner?.name}</Typography>
          </Box>
        </Box>
        <Box className='flex-grow'>
          <TabContext className='flex flex-col' value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                classes={{ indicator: classes.indicator }}
                onChange={handleChange}
                aria-label='lab API tabs example'
              >
                <Tab classes={{ selected: classes.selected }} className='capitalize' label='Description' value='1' />
                <Tab classes={{ selected: classes.selected }} className='capitalize' label='History' value='2' />
              </TabList>
            </Box>
            <TabPanel className='px-0 pb-0' value='1'>
              <Box className='flex justify-between'>
                <Box className='flex mb-8 w-5/12'>
                  <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2' src={Collection?.image}></Avatar>
                  <Box className='flex flex-col justify-center'>
                    <Typography variant='h6' color='#949496'>
                      Collection
                    </Typography>
                    <Typography variant='h5'>{Collection?.name}</Typography>
                  </Box>
                </Box>
                <Box className='flex mb-8 w-5/12'>
                  <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2' src={Creator?.avatar}></Avatar>
                  <Box className='flex flex-col justify-center'>
                    <Typography variant='h6' color='#949496'>
                      Creator
                    </Typography>
                    <Typography variant='h5'>{Creator?.name}</Typography>
                  </Box>
                </Box>
              </Box>
              <Typography variant='body1' className='mb-5 flex-grow'>
                {NFTDetail?.description}
              </Typography>
            </TabPanel>
            <TabPanel className={`px-0 pb-0 overflow-y-auto ${classes.panel}`} value='2'>
              <Box className='max-h-80'>
                {listHistory &&
                  listHistory?.nftEventHistory?.map((item, index) => (
                    <Box key={index} className='flex mb-5 pr-3'>
                      <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2' src={Owner?.avatar}></Avatar>
                      <Box className='flex flex-col justify-center flex-grow'>
                        <Typography variant='h5' color='#242424'>
                          {ACTION_TYPES?.[item.eventType]}
                        </Typography>
                        <Typography variant='h6' color='#949496' className='text-xs'>
                          {`By ${shorten(item?.byUserAddress)} at ${new Date(item?.createdAt * 1000)
                            .toISOString()
                            .slice(0, 16)
                            .replace('T', ', ')}`}
                        </Typography>
                      </Box>
                      <Typography variant='h5' color='#242424'>
                        {`${item?.price} ${item?.symbol}`}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        <Button className={classes.btnDelist} onClick={onOpenPopupDelist}>
          Delist
        </Button>
      </Box>
      <Dialog open={isOpenPopupDelist} maxWidth='lg' className='w-81 h-43'>
        <PopupSave onCancel={onClosePopupDelist} onConfirm={handleClickDelist} info={infoDelist} />
      </Dialog>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1280,
    height: 630,
    position: 'relative',
    padding: '32px 36px',
    [theme.breakpoints.down('lg')]: {
      width: 992,
    },
    [theme.breakpoints.down('md')]: {
      width: 768,
    },
    [theme.breakpoints.down('sm')]: {
      width: 540,
    },
  },
  selected: {
    color: '#242424!important',
    '& .MuiListItemIcon-root': {
      color: '#FFFFFF!important',
    },
  },
  expanded: {
    margin: '0px!important',
    minHeight: '20px!important',
  },
  indicator: {
    backgroundColor: '#242424!important',
  },
  btnDelist: {
    background: '#FF5630!important',
    width: '96px',
    color: '#FFFFFF!important',
    borderRadius: '4px',
    position: 'absolute',
    bottom: 25,
    right: 36,
    cursor: 'pointer',
  },
  panel: {
    '&::-webkit-scrollbar': {
      height: '4px',
      width: '10px;',
      border: '1px solid #E2E8F0;',
      backgroundColor: '#E2E8F0;',
      borderRadius: '100px;',
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      background: '#000;',
      borderRadius: '10px;',
      '-webkit-transition': '#E2E8F0 .2s linear,width .2s ease-in-out',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4A5568;',
      height: '40px;',
      borderRadius: '100px;',
    },
  },
}));

export default PopupDeList;
