import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { profileAction } from 'actions/profile';
import { privateRoute } from 'routes';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MenuItem = ({ name, icon, path, onClick }) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <ListItemButton
      classes={classes}
      component={path ? Link : undefined}
      to={path}
      selected={location.pathname.startsWith(path)}
      onClick={onClick}
    >
      <ListItemIcon className='min-w-min w-8'>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
    </ListItemButton>
  );
};

const MenuItemLink = ({ name, icon, path, onClick }) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <ListItemButton
      classes={classes}
      component={path ? Link : undefined}
      className='pl-8'
      to={path}
      selected={location.pathname.startsWith(path)}
      onClick={onClick}
    >
      <ListItemIcon className='min-w-min w-8'>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
    </ListItemButton>
  );
};

const SimpleAccordion = ({ name, icon }) => {
  const classes = useStyles();
  const { contractWhitelist, artistCreatorWhitelist, deListNFT } = privateRoute;
  return (
    <Accordion defaultExpanded={true} style={{ background: 'inherit' }} classes={{ expanded: classes.expanded }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className='text-white' />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        classes={{ expanded: classes.expanded }}
      >
        <ListItemIcon className='min-w-min w-8 text-white'>{icon}</ListItemIcon>
        <ListItemText classes={{ primary: 'font-medium' }} className='text-white'>
          {name}
        </ListItemText>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0px' }}>
        <List>
          <MenuItemLink
            {...contractWhitelist}
            icon={<FiberManualRecordIcon style={{ width: '10px', height: '10px' }} />}
          />
          <MenuItemLink
            {...artistCreatorWhitelist}
            icon={<FiberManualRecordIcon style={{ width: '10px', height: '10px' }} />}
          />
          <MenuItemLink {...deListNFT} icon={<FiberManualRecordIcon style={{ width: '10px', height: '10px' }} />} />
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const Menu = () => {
  const { home, artistApprove, configuration, importNFT } = privateRoute;

  return (
    <List>
      <SimpleAccordion {...importNFT} icon={<SystemUpdateAltIcon />} />
      <MenuItem {...home} icon={<InsertChartOutlinedIcon />} />
      <MenuItem {...artistApprove} icon={<ManageAccountsOutlinedIcon />} />
      <MenuItem {...configuration} icon={<SettingsOutlinedIcon />} />
      <Divider className='bg-neutral-100 my-2' />
      <MenuItem name='Logout' icon={<LogoutOutlinedIcon />} onClick={profileAction.signOut} />
    </List>
  );
};

const useStyles = makeStyles({
  root: {
    minHeight: 40,
    marginBottom: 4,
    color: '#979BAF',
    '& .MuiListItemIcon-root': {
      color: '#979BAF',
    },
    '&:hover': {
      backgroundColor: '#5D73DD !important',
      color: '#FFFFFF',
      '& .MuiListItemIcon-root': {
        color: '#FFFFFF',
      },
    },
  },
  selected: {
    backgroundColor: '#5D73DD !important',
    color: '#FFFFFF',
    '& .MuiListItemIcon-root': {
      color: '#FFFFFF',
    },
  },
  expanded: {
    margin: '0px!important',
    minHeight: '20px!important',
  },
});

export default Menu;
