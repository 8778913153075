import { Button, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

const ButtonYes = styled(Button)({
  marginRight: '6px',
  minWidth: '130px',
});

const ButtonCancel = styled(ButtonYes)({
  background: '#EBECF0!important',
});

function PopupSave({ onCancel, onConfirm, info }) {
  return (
    <div className='rounded-lg w-80  px-6 py-2 pb-6'>
      <Typography variant='body1' className='my-2 text-xl' style={{ color: '#343C63' }}>
        {info.title}
      </Typography>
      <Typography variant='body2' style={{ color: '#7A7F9A' }} className='my-2 text-neutral-100 mb-6 text-sm'>
        {info.content}
      </Typography>
      <ButtonCancel onClick={onCancel}>Cancel</ButtonCancel>
      <ButtonYes onClick={onConfirm}>Yes, continue</ButtonYes>
    </div>
  );
}

export default PopupSave;
